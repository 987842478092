<template>
    <div class="max-w-4xl mx-auto px-4 py-8 bg-white shadow-md rounded-lg">
        <h2
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-blue-500 to-blue-500 text-transparent bg-clip-text">
            Gerenciador de Perguntas
        </h2>
        <br />
        <button v-if="!showForm" @click="showForm = true"
            class="mb-4 w-full bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition duration-300 font-semibold">
            {{ isUpdate ? 'Atualizar Pergunta' : 'Adicionar Nova Pergunta' }}
        </button>

        <form v-if="showForm" @submit.prevent="handleSubmit" class="mb-8">
            <div class="mb-5" v-if="isAdmin">
                <label for="empresa" class="block text-gray-700 text-sm font-medium mb-2">Empresa:</label>
                <select id="empresa" v-model="form.empresaId"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                    <option disabled value="">Selecione uma empresa</option>
                    <option v-for="empresa in empresas" :key="empresa.id" :value="empresa.id">{{ empresa.nome }}
                    </option>
                </select>
            </div>
            <div class="mb-5">
                <label for="texto" class="block text-gray-700 text-sm font-medium mb-2">Palavra ou
                    palavras-chave:</label>
                <input type="text" id="texto" v-model="form.texto"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                <p class="text-gray-600 text-sm mt-1">Separe cada palavra-chave por vírgula.</p>
            </div>
            <div class="mb-5">
                <label for="resposta" class="block text-gray-700 text-sm font-medium mb-2">Resposta:</label>
                <textarea id="resposta" v-model="form.resposta"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"></textarea>
                <p class="text-gray-600 text-sm mt-1">Use <strong>{cliente}</strong> para inserir o nome do cliente na
                    resposta.</p>
            </div>
            <button type="submit"
                class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md w-full transition duration-300">
                {{ isUpdate ? 'Atualizar Pergunta' : 'Adicionar Pergunta' }}
            </button>
        </form>
        <p v-if="statusMessage" class="mt-6 text-center text-red-500">{{ statusMessage }}</p>
        <div>
            <h3 class="text-2xl font-semibold text-gray-800 mb-4">Lista de Perguntas</h3>
            <div class="mb-5">
                <label for="pesquisaPergunta" class="block text-gray-700 text-sm font-medium mb-2">Pesquisar:</label>
                <input type="text" id="pesquisaPergunta" v-model="termoPesquisa"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
            </div>
            <br />
            <div v-if="isAdmin">
                <div v-if="perguntasAgrupadasFiltradas">
                    <div v-for="(perguntas, empresaId) in perguntasAgrupadasFiltradas" :key="empresaId">
                        <h4 class="text-xl font-bold text-gray-800 mb-2">{{ getEmpresaNome(empresaId) }}</h4>
                        <ul class="space-y-2">
                            <li v-for="pergunta in perguntas" :key="pergunta.id"
                                class="bg-gray-100 p-4 rounded-md shadow-sm flex justify-between items-center">
                                <div>
                                    <p class="text-gray-800 font-bold"> Pergunta - {{ pergunta.texto }}</p>
                                    <p class="text-gray-600"> Resposta - {{ pergunta.resposta }}</p>
                                </div>
                                <div class="flex space-x-2">
                                    <button @click="editPergunta(pergunta)"
                                        class="text-blue-600 hover:underline">Editar</button>
                                    <button @click="confirmDeletePergunta(pergunta.id)"
                                        class="text-red-600 hover:underline">Excluir</button>
                                </div>
                            </li>
                        </ul>
                        <br />
                    </div>
                </div>
                <p v-if="Object.keys(perguntasAgrupadasFiltradas).length === 0" class="mt-4 text-center text-gray-600">
                    Nenhuma pergunta encontrada.
                </p>
            </div>
            <div v-else>
                <ul class="space-y-2">
                    <li v-for="pergunta in perguntasFiltradas" :key="pergunta.id"
                        class="bg-gray-100 p-4 rounded-md shadow-sm flex justify-between items-center">
                        <div>
                            <p class="text-gray-800 font-bold"> Pergunta - {{ pergunta.texto }}</p>
                            <p class="text-gray-600"> Resposta - {{ pergunta.resposta }}</p>
                        </div>
                        <div class="flex space-x-2">
                            <button @click="editPergunta(pergunta)"
                                class="text-blue-600 hover:underline">Editar</button>
                            <button @click="confirmDeletePergunta(pergunta.id)"
                                class="text-red-600 hover:underline">Excluir</button>
                        </div>
                    </li>
                </ul>
                <p v-if="perguntasFiltradas.length === 0" class="mt-4 text-center text-gray-600">Nenhuma pergunta
                    encontrada.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

export default {
    setup() {
        const perguntas = ref([]);
        const empresas = ref([]);
        const form = ref({ texto: '', resposta: '', empresaId: '' });
        const isAdmin = ref(false);
        const termoPesquisa = ref('');
        const isUpdate = ref(false);
        const showForm = ref(false);
        const editingPerguntaId = ref(null);
        const statusMessage = ref('');

        const getAuthHeaders = () => {
            const user = JSON.parse(localStorage.getItem('user'));
            return user && user.token ? { Authorization: `Bearer ${user.token}` } : {};
        };

        const fetchPerguntas = async () => {
            try {
                const response = await axios.get('https://whatsappback-production.up.railway.app/perguntas', {
                    headers: getAuthHeaders()
                });
                perguntas.value = response.data.sort((a, b) => a.texto.localeCompare(b.texto));
            } catch (error) {
                console.error('Erro ao carregar perguntas:', error);
            }
        };

        const fetchEmpresas = async () => {
            try {
                const response = await axios.get('https://whatsappback-production.up.railway.app/empresas', {
                    headers: getAuthHeaders()
                });
                empresas.value = response.data;
            } catch (error) {
                console.error('Erro ao carregar empresas:', error);
            }
        };

        const checkAdmin = async () => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user && user.token) {
                const response = await axios.get('https://whatsappback-production.up.railway.app/auth/me', {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                isAdmin.value = response.data.role === 'ADMIN';
            }
        };

        const handleSubmit = async () => {
            if (!isAdmin.value) {
                delete form.value.empresaId;
            }

            try {
                if (isUpdate.value) {
                    await axios.put(`https://whatsappback-production.up.railway.app/perguntas/${editingPerguntaId.value}`, form.value, {
                        headers: getAuthHeaders()
                    });
                    statusMessage.value = 'Pergunta atualizada com sucesso!';
                } else {
                    await axios.post('https://whatsappback-production.up.railway.app/perguntas', form.value, {
                        headers: getAuthHeaders()
                    });
                    statusMessage.value = 'Pergunta adicionada com sucesso!';
                }
                resetForm();
            } catch (error) {
                statusMessage.value = `Erro ao processar a solicitação: ${error.message}`;
            }
        };

        const resetForm = () => {
            form.value = { texto: '', resposta: '', empresaId: '' };
            isUpdate.value = false;
            editingPerguntaId.value = null;
            showForm.value = false;
            fetchPerguntas();
        };

        const editPergunta = (pergunta) => {
            form.value = { texto: pergunta.texto, resposta: pergunta.resposta, empresaId: pergunta.empresaId };
            isUpdate.value = true;
            editingPerguntaId.value = pergunta.id;
            showForm.value = true;
        };

        const confirmDeletePergunta = (id) => {
            if (confirm('Tem certeza que deseja excluir esta pergunta?')) {
                deletePergunta(id);
            }
        };

        const deletePergunta = async (id) => {
            try {
                await axios.delete(`https://whatsappback-production.up.railway.app/perguntas/${id}`, {
                    headers: getAuthHeaders()
                });
                fetchPerguntas();
                statusMessage.value = 'Pergunta excluída com sucesso!';
            } catch (error) {
                statusMessage.value = 'Erro ao excluir pergunta.';
            }
        };

        const getEmpresaNome = (empresaId) => {
            const empresa = empresas.value.find(e => e.id === empresaId);
            return empresa ? empresa.nome : '';
        };

        const perguntasFiltradas = computed(() => {
            const termos = termoPesquisa.value.toLowerCase().split(',').map(termo => termo.trim()).filter(Boolean);
            return perguntas.value.filter(pergunta => {
                return termos.every(termo =>
                    pergunta.texto.toLowerCase().includes(termo) ||
                    pergunta.resposta.toLowerCase().includes(termo) ||
                    getEmpresaNome(pergunta.empresaId).toLowerCase().includes(termo)
                );
            }).sort((a, b) => a.texto.localeCompare(b.texto));
        });

        const perguntasAgrupadasFiltradas = computed(() => {
            const grouped = perguntasFiltradas.value.reduce((acc, pergunta) => {
                const key = pergunta.empresaId;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(pergunta);
                return acc;
            }, {});
            return grouped;
        });

        onMounted(() => {
            fetchPerguntas();
            fetchEmpresas();
            checkAdmin();
        });

        return {
            perguntas,
            empresas,
            form,
            handleSubmit,
            editPergunta,
            confirmDeletePergunta,
            getEmpresaNome,
            perguntasAgrupadasFiltradas,
            perguntasFiltradas,
            isAdmin,
            termoPesquisa,
            isUpdate,
            showForm,
            statusMessage
        };
    }
};
</script>

<style scoped>
.numero-form input {
    width: 100%;
}
</style>

