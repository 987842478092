<template>
    <div class="max-w-lg w-full bg-white shadow-lg rounded-lg p-8 mx-auto my-10">
        <h2 class="text-4xl font-extrabold text-gray-900 mb-6 text-center">Seja Bem-vindo ao BotOverx</h2>
        <p class="text-lg text-gray-700 text-center mb-8">
            Inicie sessão no seu WhatsApp e crie perguntas e respostas que serão enviadas automaticamente aos seus
            clientes.
        </p>
    </div>
</template>

<style scoped></style>

