<template>
    <div class="max-w-4xl mx-auto px-4 py-8 bg-white shadow-md rounded-lg">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-blue-500 to-blue-500 text-transparent bg-clip-text">
            {{ isUpdate ? 'Atualizar Número' : 'Cadastrar Número' }}
        </h1>
        <br />
        <button v-if="!showForm" @click="showForm = true"
            class="mb-4 w-full bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition duration-300 font-semibold">
            {{ isUpdate ? 'Atualizar Número' : 'Cadastrar Novo Número' }}
        </button>

        <form v-if="showForm" @submit.prevent="handleSubmit">
            <div class="mb-5">
                <label for="numero" class="block text-sm font-medium text-gray-600">Número de Telefone</label>
                <input id="numero" v-model="form.numero" type="text" required
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
            <div v-if="isAdmin" class="mb-5">
                <label for="empresaId" class="block text-sm font-medium text-gray-600">Empresa</label>
                <select id="empresaId" v-model="form.empresaId" required
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value="" disabled>Selecione uma empresa</option>
                    <option v-for="empresa in empresas" :key="empresa.id" :value="empresa.id">{{ empresa.nome }}
                    </option>
                </select>
            </div>
            <button type="submit"
                class="bg-blue-600 text-white px-4 py-2 rounded-md w-full font-semibold hover:bg-blue-700 transition duration-300">
                {{ isUpdate ? 'Atualizar Número' : 'Cadastrar Número' }}
            </button>
        </form>
        <p v-if="statusMessage" class="mt-6 text-center text-red-500">{{ statusMessage }}</p>

        <div v-if="numeros.length" class="mt-8">
            <h2 class="text-2xl font-bold mb-4 text-gray-800">Números Cadastrados</h2>
            <ul class="space-y-2">
                <li v-for="numero in numeros" :key="numero.id"
                    class="bg-gray-100 p-3 rounded-md shadow-sm flex justify-between items-center">
                    <div>
                        <p><strong>Número:</strong> {{ numero.numero }}</p>
                        <p><strong>Empresa:</strong> {{ getEmpresaNome(numero.empresaId) }} </p>
                    </div>
                    <div class="flex space-x-2">
                        <button @click="editNumero(numero)" class="text-blue-600 hover:underline">Editar</button>
                        <button @click="confirmDeleteNumero(numero.id)"
                            class="text-red-600 hover:underline">Deletar</button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const form = ref({
    numero: '',
    empresaId: ''
});

const isUpdate = ref(false);
const showForm = ref(false); // Novo estado para controlar a visibilidade do formulário
const statusMessage = ref('');
const isAdmin = ref(false);
const numeros = ref([]);
const empresas = ref([]);
const editingNumeroId = ref('');

onMounted(async () => {
    await checkUserRole();
    if (!isAdmin.value) {
        await fetchUserEmpresa();
    }
    await fetchEmpresas();
    await fetchNumeros();
});

const checkUserRole = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            const response = await axios.get('https://whatsappback-production.up.railway.app/auth/me', {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            isAdmin.value = response.data.role === 'ADMIN';
        } else {
            statusMessage.value = 'Você precisa estar logado.';
        }
    } catch (error) {
        console.error('Erro ao verificar papel do usuário:', error);
        statusMessage.value = 'Erro ao verificar papel do usuário.';
    }
};

const fetchUserEmpresa = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const response = await axios.get('https://whatsappback-production.up.railway.app/empresas/minhas', {
            headers: { Authorization: `Bearer ${user.token}` },
        });
        const userEmpresa = response.data[0];
        if (userEmpresa) {
            form.value.empresaId = userEmpresa.id;
        } else {
            statusMessage.value = 'Você não possui uma empresa associada.';
        }
    } catch (error) {
        console.error('Erro ao buscar empresa do usuário:', error);
        statusMessage.value = 'Erro ao buscar empresa do usuário.';
    }
};

const fetchEmpresas = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const response = await axios.get('https://whatsappback-production.up.railway.app/empresas', {
            headers: { Authorization: `Bearer ${user.token}` },
        });
        empresas.value = response.data;
    } catch (error) {
        console.error('Erro ao buscar empresas:', error);
        statusMessage.value = 'Erro ao buscar empresas.';
    }
};

const fetchNumeros = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const response = await axios.get('https://whatsappback-production.up.railway.app/numeros', {
            headers: { Authorization: `Bearer ${user.token}` },
        });
        numeros.value = response.data;
    } catch (error) {
        console.error('Erro ao buscar números cadastrados:', error);
        statusMessage.value = 'Erro ao buscar números cadastrados.';
    }
};

const handleSubmit = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (isUpdate.value) {
            await axios.put(`https://whatsappback-production.up.railway.app/numeros/${editingNumeroId.value}`, form.value, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            statusMessage.value = 'Número atualizado com sucesso!';
        } else {
            await axios.post('https://whatsappback-production.up.railway.app/numeros', form.value, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            statusMessage.value = 'Número cadastrado com sucesso!';
        }
        form.value = { numero: '', empresaId: '' };
        isUpdate.value = false;
        editingNumeroId.value = '';
        showForm.value = false; // Ocultar o formulário após o envio
        await fetchNumeros();
    } catch (error) {
        console.error('Erro ao processar a solicitação:', error);
        statusMessage.value = 'Erro ao processar a solicitação.';
    }
};

const editNumero = (numero) => {
    form.value = { numero: numero.numero, empresaId: numero.empresaId };
    isUpdate.value = true;
    editingNumeroId.value = numero.id;
    showForm.value = true; // Mostrar o formulário quando for editar um número
};

const confirmDeleteNumero = (id) => {
    if (confirm('Tem certeza que deseja deletar este número?')) {
        deleteNumero(id);
    }
};

const deleteNumero = async (id) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        await axios.delete(`https://whatsappback-production.up.railway.app/numeros/${id}`, {
            headers: { Authorization: `Bearer ${user.token}` },
        });
        statusMessage.value = 'Número deletado com sucesso!';
        await fetchNumeros();
    } catch (error) {
        statusMessage.value = 'Erro ao deletar número.';
        console.error('Erro ao deletar número:', error);
    }
};

const getEmpresaNome = (empresaId) => {
    const empresa = empresas.value.find(e => e.id === empresaId);
    return empresa ? empresa.nome : 'Desconhecida';
};
</script>

<style scoped>
.numero-form input {
    width: 100%;
}
</style>
