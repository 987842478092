<template>
    <div class="max-w-4xl mx-auto px-4 py-8 bg-white shadow-md rounded-lg">
        <h2
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-blue-500 to-blue-500 text-transparent bg-clip-text">
            Gerenciador de Perguntas Fixas
        </h2>
        <br />
        <button v-if="!showForm && isAdmin" @click="showForm = true"
            class="mb-4 w-full bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition duration-300 font-semibold">
            {{ isUpdate ? 'Atualizar Pergunta Fixa' : 'Adicionar Nova Pergunta Fixa' }}
        </button>

        <form v-if="showForm" @submit.prevent="handleSubmit" class="mb-8">
            <div class="mb-5">
                <label for="texto" class="block text-gray-700 text-sm font-medium mb-2">Palavra ou
                    palavras-chave:</label>
                <input type="text" id="texto" v-model="form.texto"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                <p class="text-gray-600 text-sm mt-1">Separe cada palavra-chave por vírgula.</p>
            </div>
            <div class="mb-5">
                <label for="resposta" class="block text-gray-700 text-sm font-medium mb-2">Resposta:</label>
                <textarea id="resposta" v-model="form.resposta"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"></textarea>
                <p class="text-gray-600 text-sm mt-1">Use <strong>{cliente}</strong> para inserir o nome do cliente na
                    resposta.</p>
            </div>
            <button type="submit"
                class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md w-full transition duration-300">
                {{ isUpdate ? 'Atualizar Pergunta Fixa' : 'Adicionar Pergunta Fixa' }}
            </button>
        </form>
        <p v-if="statusMessage" class="mt-6 text-center text-red-500">{{ statusMessage }}</p>
        <div>
            <h3 class="text-2xl font-semibold text-gray-800 mb-4">Lista de Perguntas Fixas</h3>
            <div class="mb-5">
                <label for="pesquisaPerguntaFixa"
                    class="block text-gray-700 text-sm font-medium mb-2">Pesquisar:</label>
                <input type="text" id="pesquisaPerguntaFixa" v-model="termoPesquisa"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
            </div>
            <br />
            <ul class="space-y-2">
                <li v-for="pergunta in perguntasFiltradas" :key="pergunta.id"
                    class="bg-gray-100 p-4 rounded-md shadow-sm flex justify-between items-center">
                    <div>
                        <p class="text-gray-800 font-bold"> Pergunta - {{ pergunta.texto }}</p>
                        <p class="text-gray-600"> Resposta - {{ pergunta.resposta }}</p>
                    </div>
                    <div class="flex space-x-2" v-if="isAdmin">
                        <button @click="editPergunta(pergunta)" class="text-blue-600 hover:underline">Editar</button>
                        <button @click="confirmDeletePergunta(pergunta.id)"
                            class="text-red-600 hover:underline">Excluir</button>
                    </div>
                </li>
            </ul>
            <p v-if="perguntasFiltradas.length === 0" class="mt-4 text-center text-gray-600">Nenhuma pergunta fixa
                encontrada.</p>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

export default {
    setup() {
        const perguntas = ref([]);
        const form = ref({ texto: '', resposta: '' });
        const isAdmin = ref(false);
        const termoPesquisa = ref('');
        const isUpdate = ref(false);
        const showForm = ref(false);
        const editingPerguntaId = ref(null);
        const statusMessage = ref('');

        const getAuthHeaders = () => {
            const user = JSON.parse(localStorage.getItem('user'));
            return user && user.token ? { Authorization: `Bearer ${user.token}` } : {};
        };

        const fetchPerguntas = async () => {
            try {
                const response = await axios.get('https://whatsappback-production.up.railway.app/perguntas-fixas', {
                    headers: getAuthHeaders()
                });
                perguntas.value = response.data.sort((a, b) => a.texto.localeCompare(b.texto));
            } catch (error) {
                console.error('Erro ao carregar perguntas fixas:', error);
            }
        };

        const checkAdmin = async () => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user && user.token) {
                const response = await axios.get('https://whatsappback-production.up.railway.app/auth/me', {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                isAdmin.value = response.data.role === 'ADMIN';
            }
        };

        const handleSubmit = async () => {
            try {
                if (isUpdate.value) {
                    await axios.put(`https://whatsappback-production.up.railway.app/perguntas-fixas/${editingPerguntaId.value}`, form.value, {
                        headers: getAuthHeaders()
                    });
                    statusMessage.value = 'Pergunta fixa atualizada com sucesso!';
                } else {
                    await axios.post('https://whatsappback-production.up.railway.app/perguntas-fixas', form.value, {
                        headers: getAuthHeaders()
                    });
                    statusMessage.value = 'Pergunta fixa adicionada com sucesso!';
                }
                resetForm();
            } catch (error) {
                statusMessage.value = `Erro ao processar a solicitação: ${error.message}`;
            }
        };

        const resetForm = () => {
            form.value = { texto: '', resposta: '' };
            isUpdate.value = false;
            editingPerguntaId.value = null;
            showForm.value = false;
            fetchPerguntas();
        };

        const editPergunta = (pergunta) => {
            form.value = { texto: pergunta.texto, resposta: pergunta.resposta };
            isUpdate.value = true;
            editingPerguntaId.value = pergunta.id;
            showForm.value = true;
        };

        const confirmDeletePergunta = (id) => {
            if (confirm('Tem certeza que deseja excluir esta pergunta fixa?')) {
                deletePergunta(id);
            }
        };

        const deletePergunta = async (id) => {
            try {
                await axios.delete(`https://whatsappback-production.up.railway.app/perguntas-fixas/${id}`, {
                    headers: getAuthHeaders()
                });
                fetchPerguntas();
                statusMessage.value = 'Pergunta fixa excluída com sucesso!';
            } catch (error) {
                statusMessage.value = 'Erro ao excluir pergunta fixa.';
            }
        };

        const perguntasFiltradas = computed(() => {
            const termo = termoPesquisa.value.toLowerCase();
            return perguntas.value.filter(pergunta =>
                pergunta.texto.toLowerCase().includes(termo) ||
                pergunta.resposta.toLowerCase().includes(termo)
            ).sort((a, b) => a.texto.localeCompare(b.texto));
        });

        onMounted(() => {
            fetchPerguntas();
            checkAdmin();
        });

        return {
            perguntas,
            form,
            handleSubmit,
            editPergunta,
            confirmDeletePergunta,
            perguntasFiltradas,
            isAdmin,
            termoPesquisa,
            isUpdate,
            showForm,
            statusMessage
        };
    }
};
</script>

<style scoped>
.numero-form input {
    width: 100%;
}
</style>
