<template>
    <div class="max-w-4xl mx-auto px-4 py-8 bg-white shadow-md rounded-lg">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-blue-500 to-blue-500 text-transparent bg-clip-text">
            {{ isUpdate ? 'Atualizar Usuário' : 'Criar Usuário' }}
        </h1>
        <br />
        <button v-if="!showForm" @click="showForm = true"
            class="mb-4 w-full bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition duration-300 font-semibold">
            {{ isUpdate ? 'Atualizar Usuário' : 'Criar Novo Usuário' }}
        </button>

        <form v-if="showForm" @submit.prevent="handleSubmit">
            <div class="mb-5">
                <label for="name" class="block text-sm font-medium text-gray-600">Nome</label>
                <input id="name" v-model="form.name" type="text"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    required />
            </div>
            <div class="mb-5">
                <label for="email" class="block text-sm font-medium text-gray-600">Email</label>
                <input id="email" v-model="form.email" type="email"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    required />
            </div>
            <div class="mb-5">
                <label for="username" class="block text-sm font-medium text-gray-600">Nome de Usuário</label>
                <input id="username" v-model="form.username" type="text"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    required />
            </div>
            <div class="mb-5">
                <label for="role" class="block text-sm font-medium text-gray-600">Papel do Usuário</label>
                <select id="role" v-model="form.role"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    required>
                    <option value="" disabled>Selecione o papel do usuário</option>
                    <option value="ADMIN">Admin</option>
                    <option value="USER">User</option>
                </select>
            </div>
            <div class="mb-5">
                <label for="password" class="block text-sm font-medium text-gray-600">Senha</label>
                <input id="password" v-model="form.password" type="password"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    :required="!isUpdate" />
            </div>
            <button type="submit"
                class="bg-blue-600 text-white px-4 py-2 rounded-md w-full font-semibold hover:bg-blue-700 transition duration-300">
                {{ isUpdate ? 'Atualizar' : 'Criar' }}
            </button>
        </form>
        <p v-if="statusMessage" class="mt-6 text-center text-red-500">{{ statusMessage }}</p>
        <h2 class="text-2xl font-bold mt-8 text-gray-800">Usuários Criados</h2>
        <br />
        <div class="mb-5">
            <label for="search" class="block text-sm font-medium text-gray-700">Pesquisar Usuário</label>
            <input id="search" v-model="searchTerm" type="text"
                class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
        </div>
        <ul class="space-y-2 mt-4">
            <li v-for="user in filteredUsers" :key="user.id"
                class="bg-gray-100 p-3 rounded-md shadow-sm flex justify-between items-center">
                <div>
                    <span class="font-medium text-gray-800">{{ user.name }} ({{ user.isActive ? 'Ativo' : 'Inativo'
                        }})</span>
                </div>
                <div class="flex space-x-2">
                    <button @click="editUser(user)" class="text-blue-600 hover:underline">Editar</button>
                    <button @click="confirmDeleteUser(user.id)" class="text-red-600 hover:underline">Deletar</button>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

const form = ref({
    name: '',
    email: '',
    username: '',
    password: '',
    role: '' // Adiciona o campo role
});

const isUpdate = ref(false);
const showForm = ref(false);
const statusMessage = ref('');
const users = ref([]);
const searchTerm = ref('');
const editingUserId = ref('');

const handleSubmit = async () => {
    try {
        if (isUpdate.value) {
            const updatedForm = { ...form.value };
            if (!updatedForm.password) {
                delete updatedForm.password;
            }
            await axios.put(`https://whatsappback-production.up.railway.app/users/${editingUserId.value}`, updatedForm);
            statusMessage.value = 'Usuário atualizado com sucesso!';
        } else {
            await axios.post('https://whatsappback-production.up.railway.app/users', form.value);
            statusMessage.value = 'Usuário criado com sucesso!';
        }
        form.value = { name: '', email: '', username: '', password: '', role: '' }; // Reseta o campo role
        isUpdate.value = false;
        showForm.value = false;
        editingUserId.value = '';
        await fetchUsers();
    } catch (error) {
        statusMessage.value = 'Ocorreu um erro ao processar a solicitação.';
        console.error('Erro ao enviar o formulário:', error);
    }
};

const fetchUsers = async () => {
    try {
        const response = await axios.get('https://whatsappback-production.up.railway.app/users');
        users.value = response.data;
    } catch (error) {
        console.error('Erro ao buscar usuários:', error);
    }
};

const editUser = (user) => {
    form.value = { ...user, password: '' };
    isUpdate.value = true;
    showForm.value = true;
    editingUserId.value = user.id;
};

const confirmDeleteUser = (id) => {
    if (confirm('Tem certeza que deseja deletar este usuário?')) {
        deleteUser(id);
    }
};

const deleteUser = async (id) => {
    try {
        await axios.delete(`https://whatsappback-production.up.railway.app/users/${id}`);
        statusMessage.value = 'Usuário deletado com sucesso!';
        await fetchUsers();
    } catch (error) {
        statusMessage.value = 'Erro ao deletar usuário.';
        console.error('Erro ao deletar usuário:', error);
    }
};

const filteredUsers = computed(() => {
    const term = searchTerm.value.toLowerCase();
    return users.value.filter(user =>
        user.name.toLowerCase().includes(term) ||
        user.email.toLowerCase().includes(term) ||
        user.username.toLowerCase().includes(term)
    );
});

onMounted(async () => {
    await fetchUsers();
});
</script>

<style scoped>
.user-form input {
    width: 100%;
}
</style>
