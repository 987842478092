<template>
  <div class="max-w-4xl mx-auto px-4 py-8 bg-white shadow-md rounded-lg">
    <h1
        class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-blue-500 to-blue-500 text-transparent bg-clip-text">
      {{ isUpdate ? 'Atualizar Empresa' : 'Criar Empresa' }}
    </h1>
    <br />
    <button v-if="!showForm" @click="showForm = true"
            class="mb-4 w-full bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition duration-300 font-semibold">
      {{ isUpdate ? 'Atualizar Empresa' : 'Criar Nova Empresa' }}
    </button>

    <form v-if="showForm" @submit.prevent="handleSubmit">
      <div class="mb-5">
        <label for="nome" class="block text-sm font-medium text-gray-600">Nome da Empresa</label>
        <input id="nome" v-model="form.nome" type="text"
               class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
               required />
      </div>
      <div class="mb-5">
        <label for="ifoodStoreId" class="block text-sm font-medium text-gray-600">ID da Loja no iFood</label>
        <input id="ifoodStoreId" v-model="form.ifoodStoreId" type="text"
               class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
               required />
      </div>
      <div class="mb-5">
        <label for="orderingStoreId" class="block text-sm font-medium text-gray-600">ID da Loja no Ordering</label>
        <input id="orderingStoreId" v-model="form.orderingStoreId" type="text"
               class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
               required />
      </div>
      <button type="submit"
              class="bg-blue-600 text-white px-4 py-2 rounded-md w-full font-semibold hover:bg-blue-700 transition duration-300">
        {{ isUpdate ? 'Atualizar' : 'Criar' }}
      </button>
    </form>
    <p v-if="statusMessage" class="mt-6 text-center text-red-500">{{ statusMessage }}</p>
    <h2 class="text-2xl font-bold mt-8 text-gray-800">Empresas Cadastradas</h2>
    <br />
    <div class="mb-5">
      <label for="search" class="block text-sm font-medium text-gray-700">Pesquisar Empresa</label>
      <input id="search" v-model="searchTerm" type="text"
             class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
    </div>
    <ul class="space-y-2 mt-4">
      <li v-for="empresa in filteredEmpresas" :key="empresa.id"
          class="bg-gray-100 p-3 rounded-md shadow-sm flex justify-between items-center">
        <div>
          <span class="font-medium text-gray-800">{{ empresa.nome }} (iFood ID: {{ empresa.ifoodStoreId }}, Ordering
            ID: {{ empresa.orderingStoreId }})</span>
        </div>
        <div class="flex space-x-2">
          <button @click="editEmpresa(empresa)" class="text-blue-600 hover:underline">Editar</button>
          <button @click="confirmDeleteEmpresa(empresa.id)" class="text-red-600 hover:underline">Deletar</button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

const form = ref({
  nome: '',
  ifoodStoreId: '',
  orderingStoreId: '',
});

const isUpdate = ref(false);
const showForm = ref(false);
const statusMessage = ref('');
const empresas = ref([]);
const searchTerm = ref('');
const editingEmpresaId = ref('');

const handleSubmit = async () => {
  try {
    if (isUpdate.value) {
      await axios.put(`https://apiifood-production.up.railway.app/empresas/${editingEmpresaId.value}`, form.value);
      statusMessage.value = 'Empresa atualizada com sucesso!';
    } else {
      await axios.post('https://apiifood-production.up.railway.app/empresas', form.value);
      statusMessage.value = 'Empresa criada com sucesso!';
    }
    form.value = { nome: '', ifoodStoreId: '', orderingStoreId: '' };
    isUpdate.value = false;
    showForm.value = false;
    editingEmpresaId.value = '';
    await fetchEmpresas();
  } catch (error) {
    statusMessage.value = 'Ocorreu um erro ao processar a solicitação.';
    console.error('Erro ao enviar o formulário:', error);
  }
};

const fetchEmpresas = async () => {
  try {
    const response = await axios.get('https://apiifood-production.up.railway.app/empresas');
    empresas.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar empresas:', error);
  }
};

const editEmpresa = (empresa) => {
  form.value = { ...empresa };
  isUpdate.value = true;
  showForm.value = true;
  editingEmpresaId.value = empresa.id;
};

const confirmDeleteEmpresa = (id) => {
  if (confirm('Tem certeza que deseja deletar esta empresa?')) {
    deleteEmpresa(id);
  }
};

const deleteEmpresa = async (id) => {
  try {
    await axios.delete(`https://apiifood-production.up.railway.app/empresas/${id}`);
    statusMessage.value = 'Empresa deletada com sucesso!';
    await fetchEmpresas();
  } catch (error) {
    statusMessage.value = 'Erro ao deletar empresa.';
    console.error('Erro ao deletar empresa:', error);
  }
};

const filteredEmpresas = computed(() => {
  const term = searchTerm.value.toLowerCase();
  return empresas.value.filter(empresa =>
      empresa.nome.toLowerCase().includes(term) ||
      empresa.ifoodStoreId.toLowerCase().includes(term) ||
      empresa.orderingStoreId.toLowerCase().includes(term)
  );
});

onMounted(async () => {
  await fetchEmpresas();
});
</script>

<style scoped>
/* Estilos personalizados podem ser adicionados aqui */
</style>
