<template>
    <div class="flex flex-col min-h-screen">
        <header class="bg-slate-900 text-gray-200 p-4 flex justify-between items-center">
            <h1 class="text-xl font-bold">
                <font-awesome-icon icon="robot" class="mr-2" /> Bot Overx
            </h1>
            <nav class="space-x-4">
                <router-link to="/">
                    <button @click="logout"
                        class="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        <font-awesome-icon icon="sign-in-alt" class="mr-2" /> Sair
                    </button>
                </router-link>
            </nav>
        </header>
        <div class="flex flex-1">
            <div class="w-64 bg-slate-900 text-gray-200 flex flex-col">
                <div class="flex-grow p-4 space-y-2">
                    <router-link to="/user/dashboard" class="block px-4 py-2 rounded hover:bg-gray-700">
                        <font-awesome-icon icon="home" class="mr-2" /> Inicio
                    </router-link>
                    <router-link to="/user/session" class="block px-4 py-2 rounded hover:bg-gray-700">
                        <font-awesome-icon icon="sign-in-alt" class="mr-2" /> Iniciar Sessão
                    </router-link>
                    <router-link to="/user/perguntas" class="block px-4 py-2 rounded hover:bg-gray-700">
                        <font-awesome-icon icon="question" class="mr-2" /> Criar Perguntas
                    </router-link>
                </div>
            </div>
            <div class="flex-grow p-6 bg-gray-100">
                <router-view />
            </div>
        </div>
        <footer class="bg-slate-950 text-gray-200 text-center p-4 text-sm">
            © 2024 Overx. Todos os direitos reservados.
            <div class="text-sm text-gray-500">
                Desenvolvido por <a href="https://www.gabimottadev.com" class="hover:underline"
                    target="_blank">gabimottadev</a>
            </div>
        </footer>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const logout = () => {
    localStorage.removeItem('user');
    router.push('/');
};
</script>

