<template>
  <div class="max-w-6xl mx-auto px-4 py-8 bg-white shadow-md rounded-lg">
    <h1
        class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-blue-500 to-blue-500 text-transparent bg-clip-text">
      Gerenciar Pedidos
    </h1>

    <div class="mt-6 mb-5 flex justify-between items-center">
      <div class="w-1/3">
        <label for="storeId" class="block text-sm font-medium text-gray-700">ID da Loja</label>
        <input id="storeId" v-model="orderingStoreId" type="text"
               class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
      </div>
      <button @click="fetchOrders"
              class="bg-blue-600 text-white px-4 py-2 rounded-md font-semibold hover:bg-blue-700 transition duration-300">
        Buscar Pedidos
      </button>
    </div>

    <div class="mt-6 mb-5">
      <label for="search" class="block text-sm font-medium text-gray-700">Pesquisar Pedido</label>
      <input id="search" v-model="searchTerm" type="text"
             class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
    </div>

    <ul class="space-y-4 mt-4">
      <li v-for="order in filteredOrders" :key="order.id"
          class="bg-gray-100 p-4 rounded-md shadow-sm flex justify-between items-center">
        <div>
          <h2 class="font-semibold text-gray-800">Pedido #{{ order.id }}</h2>
          <p class="text-sm text-gray-600">Cliente: {{ order.customer?.name || 'N/A' }}</p>
          <p class="text-sm text-gray-600">Total: {{ formatCurrency(order.total) }}</p>
          <p class="text-sm text-gray-600">Status: {{ order.status }}</p>
        </div>
        <div class="flex space-x-2">
          <button @click="viewOrderDetails(order)" class="text-blue-600 hover:underline">Ver Detalhes</button>
          <button @click="processOrder(order)" class="text-green-600 hover:underline">Processar</button>
        </div>
      </li>
    </ul>

    <p v-if="statusMessage" class="mt-6 text-center text-red-500">{{ statusMessage }}</p>

    <modal v-if="selectedOrder" @close="selectedOrder = null">
      <template v-slot:header>
        <h3 class="text-xl font-bold">Detalhes do Pedido #{{ selectedOrder.id }}</h3>
      </template>
      <template v-slot:body>
        <p><strong>Cliente:</strong> {{ selectedOrder.customer?.name || 'N/A' }}</p>
        <p><strong>Total:</strong> {{ formatCurrency(selectedOrder.total) }}</p>
        <p><strong>Status:</strong> {{ selectedOrder.status }}</p>
        <p><strong>Produtos:</strong></p>
        <ul class="list-disc ml-5">
          <li v-for="item in selectedOrder.items" :key="item.name">
            {{ item.name }} - Quantidade: {{ item.quantity }} - Preço: {{ formatCurrency(item.unitPrice) }}
          </li>
        </ul>
      </template>
      <template v-slot:footer>
        <button @click="selectedOrder = null"
                class="bg-red-600 text-white px-4 py-2 rounded-md font-semibold hover:bg-red-700 transition duration-300">
          Fechar
        </button>
      </template>
    </modal>
  </div>
</template>

<script setup>
import {ref, computed} from 'vue';
import axios from 'axios';

const orders = ref([]);
const searchTerm = ref('');
const statusMessage = ref('');
const selectedOrder = ref(null);
const orderingStoreId = ref('');

const fetchOrders = async () => {
  try {
    if (!orderingStoreId.value) {
      statusMessage.value = 'Por favor, insira o ID da loja.';
      return;
    }

    const response = await axios.get(`https://apiifood-production.up.railway.app/orders/${orderingStoreId.value}`);

    if (Array.isArray(response.data)) {
      orders.value = response.data;
      statusMessage.value = '';
    } else {
      console.error('A resposta da API não é um array:', response.data);
      orders.value = [];
    }

  } catch (error) {
    statusMessage.value = 'Erro ao buscar pedidos.';
    console.error('Erro ao buscar pedidos:', error);
    orders.value = [];
  }
};

const filteredOrders = computed(() => {
  const term = searchTerm.value.toLowerCase();
  return orders.value.filter(order => {
    const orderId = order.id ? order.id.toLowerCase() : '';
    const customerName = order.customer?.name ? order.customer.name.toLowerCase() : '';
    return orderId.includes(term) || customerName.includes(term);
  });
});

const viewOrderDetails = (order) => {
  selectedOrder.value = order;
};

const processOrder = async (order) => {
  try {
    await axios.post(`https://apiifood-production.up.railway.app/orders/process/${order.id}`);
    statusMessage.value = 'Pedido processado com sucesso!';
    await fetchOrders(orderingStoreId.value);
  } catch (error) {
    statusMessage.value = 'Erro ao processar o pedido.';
    console.error('Erro ao processar o pedido:', error);
  }
};

const formatCurrency = (value) => {
  if (typeof value !== 'number') {
    return 'N/A'; // ou '-' ou qualquer string padrão que você queira exibir
  }
  return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
};
</script>

<style scoped>
/* Estilos personalizados para o OrderManager */
</style>
